import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  Label,
  Form,
  FormGroup,
  Toast,
  ToastHeader,
  ToastBody,
  ModalFooter,
} from 'reactstrap';
import { logger } from '../../logging';
import moment from 'moment';
import { ContactsMigrationArgs } from './ContactsMigrationArgs';
import { WorkflowTypes } from './Workflows';
import { getDAMApp } from '../../services/firebase';
const R = require('ramda');

const workflowsStyles = require('./workflows.css');

export enum WorkflowSchedules {
  EVERY_30_MINS = 'every_30_mins',
  HOURLY = 'hourly',
  DAILY = 'daily',
}

export const scheduleCronMap = {
  '*/30 * * * *': WorkflowSchedules.EVERY_30_MINS,
  '0 * * * *': WorkflowSchedules.HOURLY,
  '0 0 * * *': WorkflowSchedules.DAILY,
};

const cronMap = R.invertObj(scheduleCronMap);

export const scheduleNameMap = {
  every_30_mins: 'Every 30 minutes',
  hourly: 'Hourly',
  daily: 'Daily',
};

const DEFAULT_END_DATE = moment().add(6, 'M').unix();
const DEFAULT_STORE_END_DATE = moment().add(12, 'M').unix();

export const WorkflowModal = ({ workflow, isOpen, toggle, setWorkflow, user, isWorkflowsAdmin }) => {
  const damApp = getDAMApp();
  const [isNew, setIsNew] = useState(true);
  const [workflowName, setWorkflowName] = useState('');
  const [workflowDescription, setWorkflowDescription] = useState('');
  const [workflowType, setWorkflowType] = useState(WorkflowTypes.MIGRATE_CONTACTS);
  const [workflowContinuous, setWorkflowContinuous] = useState(true);
  const [workflowSchedule, setWorkflowSchedule] = useState(cronMap[WorkflowSchedules.DAILY]);
  const [workflowScheduleReadable, setWorkflowScheduleReadable] = useState(WorkflowSchedules.DAILY);
  const [workflowEndDate, setWorkflowEndDate] = useState(DEFAULT_END_DATE);
  //const [workflowBackfill, setWorkflowBackfill] = useState(false);
  const [workflowArgs, setWorkflowArgs] = useState(null);
  const [workflowArgsValid, setWorkflowArgsValid] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (workflow === null) {
      return;
    }

    if (isMounted) {
      setIsNew(false);
      setWorkflowName(workflow.name || '');
      setWorkflowDescription(workflow.description || '');
      setWorkflowType(workflow.type || WorkflowTypes.MIGRATE_CONTACTS);
      setWorkflowContinuous(workflow.continuous || true);
      setWorkflowSchedule(workflow.schedule || cronMap[WorkflowSchedules.DAILY]);
      setWorkflowScheduleReadable((workflow.schedule && scheduleCronMap[workflow.schedule]) || WorkflowSchedules.DAILY);
      setWorkflowEndDate(workflow.endDate || DEFAULT_END_DATE);
      //setWorkflowBackfill(workflow.backfill || false);
      setWorkflowArgs(workflow.args || null);
    }

    return () => {
      isMounted = false;
    };
  }, [workflow]);

  useEffect(() => {
    logger.debug(`useEffect workflowArgs: ${workflowArgs}`);
    let isMounted = true;
    if (workflowArgs === null) {
      return;
    }

    if (isMounted) {
      if (workflowArgs.is_store === true) {
        setWorkflowEndDate(DEFAULT_STORE_END_DATE);
      } else {
        setWorkflowEndDate(DEFAULT_END_DATE);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [workflowArgs]);

  const handleClose = () => {
    setWorkflowName('');
    setWorkflowDescription('');
    setWorkflowType(WorkflowTypes.MIGRATE_CONTACTS);
    setWorkflowSchedule(cronMap[WorkflowSchedules.DAILY]);
    setWorkflowScheduleReadable(WorkflowSchedules.DAILY);
    setWorkflowEndDate(DEFAULT_END_DATE);
    setWorkflowContinuous(true);
    //setWorkflowBackfill(false);
    setWorkflowArgs(null);
    setWorkflowArgsValid(false);
    setSaving(false);
    setWorkflow(null);
    setWorkflow(null);
    toggle(false);
  };

  const toggleSaveModal = () => {
    setSaveModal(!saveModal);
  };

  const saveWorkflow = async () => {
    logger.debug(`saveWorkflow called - isNew: ${isNew}`);
    setSaving(true);
    const now = moment().unix();
    const validWorkflow = {
      ...workflow,
      name: workflowName,
      description: workflowDescription,
      type: workflowType,
      continuous: workflowContinuous,
      schedule: workflowSchedule,
      //backfill: workflowBackfill,
      args: workflowArgs,
      modified: now,
      endDate: workflowEndDate,
    };

    if (isNew) {
      try {
        validWorkflow['created'] = now;
        validWorkflow['createdBy'] = { uid: user.uid, email: user.email };
        logger.info(`[saveWorkflow] saving new validWorkflow: ${JSON.stringify(validWorkflow)}`);
        const callable = damApp.functions().httpsCallable('accountManager-callableCreateWorkflow');
        const res = await callable(validWorkflow);
        logger.debug(`[saveWorkflow] create res: ${res}, ${JSON.stringify(res)}`);
      } catch (e) {
        logger.error(`[saveWorkflow] error saving new workflow: ${e}`);
      }
    } else {
      try {
        const workflowRef = damApp.database().ref(`workflows/${workflow.key}`);
        logger.info(`saving existing validWorkflow: ${JSON.stringify(validWorkflow)}`);
        await workflowRef.update(validWorkflow);
      } catch (e) {
        logger.error(`[saveWorkflow] error saving new workflow: ${e}`);
      }
    }

    logger.debug(`[saveWorkflow] about to call handleClose()`);
    toggleSaveModal();
    handleClose();
  };

  const handleScheduleChange = (e) => {
    const scheduleReadable = e.target.value;
    setWorkflowScheduleReadable(scheduleReadable);
    setWorkflowSchedule(cronMap[scheduleReadable]);
  };

  const handleSetWorkflowArgs = (e) => {
    logger.debug(`[handleSetWorkflowArgs] e: ${JSON.stringify(e)}`);
    setWorkflowArgs(e);
  };

  const handleSetWorkflowType = (e) => {
    const type = e.target.value;

    if (type === WorkflowTypes.MIGRATE_CONTACTS) {
      setWorkflowScheduleReadable(WorkflowSchedules.DAILY);
      setWorkflowSchedule(cronMap[WorkflowSchedules.DAILY]);
    }

    setWorkflowType(type);
  };

  const handleEndDateChange = (e) => {
    const date = e.target.value;
    const ts = moment(date).unix();
    const dt = moment.unix(ts).format('YYYY-MM-DD');
    logger.debug(`[handleEndDateChange] date: ${date} (${ts}) (${dt})`);
    setWorkflowEndDate(ts);
  };

  const isArchived = (workflow && workflow.isArchived) || false;
  const createdBy = (workflow && workflow.createdBy) || { uid: user.uid, email: user.email };

  const saveDisabled =
    workflowArgsValid !== true || workflowName === '' || saveModal === true ? true : false || isArchived === true;
  const scheduleDisabled = isNew === false || workflowType === WorkflowTypes.MIGRATE_CONTACTS;
  const nowUnix = moment().unix();
  const savedEndDate = (workflow && workflow.endDate) || DEFAULT_END_DATE;
  const isEndDateInPast = nowUnix > savedEndDate;
  const createdByEmail = createdBy && createdBy.email;
  const userIsCreator = user.email === createdByEmail;
  const canModifyEndDate = (!isEndDateInPast || (isEndDateInPast && !userIsCreator)) && isArchived === false;

  return (
    <Modal isOpen={isOpen} toggle={handleClose} className={workflowsStyles.workflowModal}>
      <ModalHeader toggle={handleClose} className={workflowsStyles.workflowModalHeader}>
        {isNew === true
          ? 'Create New Workflow'
          : `${isArchived ? '[ARCHIVED] View' : ''} Workflow: ${workflow && workflow.name} (${
              workflow && workflow.key
            })`}
      </ModalHeader>
      <ModalBody className={workflowsStyles.workflowModalBody}>
        <Form>
          {isNew === false && workflow ? (
            <Toast className={workflowsStyles.toast}>
              <ToastHeader>Workflow Details</ToastHeader>
              <ToastBody>
                <FormGroup row className={workflowsStyles.workflowModalBodyRow}>
                  <Label for="id" sm={2}>
                    ID
                  </Label>
                  <Col sm={10}>
                    <Input type="text" value={workflow.key} id="id" disabled={true} />
                  </Col>
                </FormGroup>
                <FormGroup row className={workflowsStyles.workflowModalBodyRow}>
                  <Label for="status" sm={2}>
                    Status
                  </Label>
                  <Col sm={10}>
                    <Input type="text" value={workflow.status} id="status" disabled={true} />
                  </Col>
                </FormGroup>
                <FormGroup row className={workflowsStyles.workflowModalBodyRow}>
                  <Label for="created" sm={2}>
                    Created
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      value={
                        workflow.created ? moment.unix(parseInt(workflow.created)).format('YYYY-MM-DD HH:mm:ss') : ''
                      }
                      id="created"
                      disabled={true}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row className={workflowsStyles.workflowModalBodyRow}>
                  <Label for="created_by" sm={2}>
                    Created By
                  </Label>
                  <Col sm={10}>
                    <Input type="text" value={createdByEmail} id="created_by" disabled={true} />
                  </Col>
                </FormGroup>
                <FormGroup row className={workflowsStyles.workflowModalBodyRow}>
                  <Label for="modified" sm={2}>
                    Modified
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      value={
                        workflow.modified ? moment.unix(parseInt(workflow.modified)).format('YYYY-MM-DD HH:mm:ss') : ''
                      }
                      id="modified"
                      disabled={true}
                    />
                  </Col>
                </FormGroup>
              </ToastBody>
            </Toast>
          ) : null}
          <Toast className={workflowsStyles.toast}>
            <ToastHeader>General Workflow Options</ToastHeader>
            <ToastBody>
              <FormGroup row className={workflowsStyles.workflowModalBodyRow}>
                <Label for="name" sm={2}>
                  Name
                </Label>
                <Col sm={10}>
                  <Input
                    type="text"
                    value={workflowName}
                    id="name"
                    onChange={(e) => setWorkflowName(e.target.value)}
                    disabled={isArchived}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className={workflowsStyles.workflowModalBodyRow}>
                <Label for="description" sm={2}>
                  Description
                </Label>
                <Col sm={10}>
                  <Input
                    type="textarea"
                    value={workflowDescription}
                    id="description"
                    onChange={(e) => setWorkflowDescription(e.target.value)}
                    disabled={isArchived}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className={workflowsStyles.workflowModalBodyRow}>
                <Label for="type" sm={2}>
                  Type
                </Label>
                <Col sm={10}>
                  <Input
                    type="select"
                    value={workflowType}
                    id="type"
                    disabled={!isNew}
                    onChange={handleSetWorkflowType}
                  >
                    <option value={WorkflowTypes.MIGRATE_CONTACTS} key={WorkflowTypes.MIGRATE_CONTACTS}>
                      Contacts Migration
                    </option>
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row className={workflowsStyles.workflowModalBodyRow}>
                <Label for="continuous" sm={2}>
                  Continuous
                </Label>
                <Col sm={10}>
                  <Input
                    type="checkbox"
                    checked={workflowContinuous}
                    id="continuous"
                    className={workflowsStyles.workflowModalCheckbox}
                    onChange={(e) => setWorkflowContinuous(e.target.checked)}
                    disabled={true}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className={workflowsStyles.workflowModalBodyRow}>
                <Label for="endDate" sm={2}>
                  End Date
                </Label>
                <Col sm={2}>
                  <Input
                    type="date"
                    value={moment.unix(workflowEndDate).format('YYYY-MM-DD')}
                    id="endDate"
                    onChange={handleEndDateChange}
                    disabled={!canModifyEndDate}
                  />
                </Col>
                <Col sm={8}></Col>
              </FormGroup>
              {workflowContinuous && (
                <FormGroup row className={workflowsStyles.workflowModalBodyRow}>
                  <Label for="schedule" sm={2}>
                    Schedule
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="select"
                      value={workflowScheduleReadable}
                      id="schedule"
                      disabled={scheduleDisabled}
                      onChange={handleScheduleChange}
                    >
                      <option value="" key="">
                        Please select
                      </option>
                      <option value={WorkflowSchedules.EVERY_30_MINS} key={WorkflowSchedules.EVERY_30_MINS}>
                        {scheduleNameMap[WorkflowSchedules.EVERY_30_MINS]}
                      </option>
                      <option value={WorkflowSchedules.HOURLY} key={WorkflowSchedules.HOURLY}>
                        {scheduleNameMap[WorkflowSchedules.HOURLY]}
                      </option>
                      <option value={WorkflowSchedules.DAILY} key={WorkflowSchedules.DAILY}>
                        {scheduleNameMap[WorkflowSchedules.DAILY]}
                      </option>
                    </Input>
                  </Col>
                </FormGroup>
              )}
              {workflowContinuous && (
                <FormGroup row className={workflowsStyles.workflowModalBodyRow}>
                  <Label for="workflowSchedule" sm={2}>
                    Schedule CRON
                  </Label>
                  <Col sm={10}>
                    <Input type="text" value={workflowSchedule} id="workflowSchedule" disabled={true} />
                  </Col>
                </FormGroup>
              )}
            </ToastBody>
          </Toast>
          {/* NOW FOR TYPE SPECIFIC OPTIONS */}
          {workflowType === WorkflowTypes.MIGRATE_CONTACTS ? (
            <ContactsMigrationArgs
              args={workflowArgs}
              setWorkflowArgs={handleSetWorkflowArgs}
              setWorkflowArgsValid={setWorkflowArgsValid}
              disabled={!isNew}
            />
          ) : null}
          <Row>
            <Col>
              <Button disabled={saveDisabled} onClick={toggleSaveModal}>
                Save
              </Button>
            </Col>
          </Row>
        </Form>
        <Row>
          <Modal isOpen={saveModal} toggle={(e) => toggleSaveModal()}>
            <ModalHeader toggle={(e) => toggleSaveModal()}>Please Confirm Save</ModalHeader>
            <ModalBody>{`Please confirm that you'd like to save the workflow.`}</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={async () => await saveWorkflow()} disabled={saving}>
                Confirm
              </Button>{' '}
              <Button color="secondary" onClick={(e) => toggleSaveModal()} disabled={saving}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Row>
      </ModalBody>
    </Modal>
  );
};
