import * as React from 'react';
import { useState, useEffect } from 'react';
import * as firebaseService from '../../../services/firebase';
import { Loader } from '../../../components/Loader';
import { useObjectVal } from 'react-firebase-hooks/database';
import {
  SelectColumnFilter,
  SelectColumnFilterFactory,
} from '../../../components/table/SelectColumnfilter';
import { fuzzyTextFilterFnExp } from '../../accounts/AccountsContainer';
import { DefaultColumnFilter } from '../../../components/table/DefaultFilter';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { NumberFilter } from '../../../components/table/NumberFilter';
import { TextwordsTable } from './TextwordsTable';
import { Alert, Button, Col, Collapse, Input, Row } from 'reactstrap';
import moment from 'moment';
import { CrossIcon, TickIcon } from '../../../components/Icons';
import { logger } from '../../../logging';
import { ColumnsSelector } from '../../../components/table/ColumnsSelector';
import { TextwordMappingModal } from './TextwordMappingModal';
import { useFeature } from 'flagged';
import { checkOptinExists } from '../../addressbooks/Addressbooks';
import { getPartyServiceRecord } from '../../tags/Tags';
import {
  DDTableFilters,
  countries as getCountries,
} from '../../../components/DDTableFilters';
const R = require('ramda');

const slicktextStyles = require('./slicktext.css');
const pagesStyles = require('../../pages.css');
const iconStyles = require('../../../components/icons.css');

const isNilOrEmpty = R.either(R.isNil, R.isEmpty);

export interface Textword {
  id: number;
  accountId: string;
  accountName: string;
  accountid_id: string;
  added: string;
  ageRequirement: number;
  autoReply: string;
  canopusId?: string;
  canopus_verified: string;
  contactsCount: number;
  contacts_count: number;
  country: string;
  doubleOptIn: number;
  lastSynced: number;
  modified: number;
  optOuts: number;
  sentToBQ?: number;
  shortcode: number;
  termsLink: string;
  textNumber: number;
  verifiedcanopus: boolean;
  verifiedcanopus_accountid_id: string;
  verifiedcanopus_senttobq: string;
  verifiedpartyid: boolean;
  verifiedpartyid_accountid_id: string;
  verifiedpartyid_senttobq: string;
  word: string;
  label?: string;
}

interface Textwords {
  textwords: Textword[];
}

export const Textwords = ({
  account,
  enumerations,
  labels,
  countriesModel,
  countries,
}) => {
  const damApp = firebaseService.getDAMApp();
  const textwordMappingEnabled = useFeature('textword-mapping');
  const TABLE_NAME = 'textwords';
  const textwordsRef = damApp
    .database()
    .ref('textwords')
    .orderByChild('accountId')
    .equalTo(`${account?.id}`);

  const [values, setValues] = useState([]);
  const [mappingModalIsOpen, setMappingModalIsOpen] = useState(false);
  const [mappingTextword, setMappingTextword] = useState({});
  const [mappingTextwordIndex, setMappingTextwordIndex] = useState(null);

  const [filteredValues, setFilteredValues] = useState([]);
  const [isFiltersSelectOpen, setIsFiltersSelectOpen] = useState(true);
  const [filterLoading, setFilterLoading] = useState(!account ? true : false);
  const [countryList, setCountryList] = useState([]);
  const [isColumnsSelectOpen, setIsColumnsSelectOpen] = useState(false);

  const [textwordsList, loading, error] = !account
    ? [React.useMemo(() => filteredValues, [filteredValues]), false, undefined]
    : useObjectVal(textwordsRef as any);

  const uid = damApp.auth().currentUser.uid;
  const userColsRef = damApp
    .database()
    .ref(`user_preferences/${uid}/columns/${TABLE_NAME}`);
  const [userCols, userColsLoading, userColsError] = useObjectVal(
    userColsRef as any
  );

  logger.debug(`countries = ${JSON.stringify(countries)}`);
  const countriesValues = (Object.values(countries) as any) || [];
  logger.debug(`countriesValues = ${JSON.stringify(countriesValues)}`);
  const countryOptions = R.map((country) => {
    // logger.debug(
    //   `country.value: ${country.value}, country.label: ${country.label}`
    // );
    return (
      <option value={country.value} key={country.value}>
        {country.label}
      </option>
    );
  }, countriesValues);

  const labelCodes = Object.keys(labels);
  const labelOptions = R.map((labelCode) => {
    return (
      <option value={labelCode} key={labelCode}>
        {labels[labelCode].label}
      </option>
    );
  }, labelCodes);

  const shortCodes = account?.shortLongCodes || [];
  const shortCodeOptions = R.map((shortCode) => {
    return (
      <option value={shortCode} key={shortCode}>
        {shortCode}
      </option>
    );
  }, shortCodes);

  const categories = enumerations.enumerations.textwordCategories;
  const categoryCodes = Object.keys(categories);
  const categoryOptions = R.map((categoryCode) => {
    return (
      <option value={categoryCode} key={categoryCode}>
        {categories[categoryCode].label}
      </option>
    );
  }, categoryCodes);

  useEffect(() => {
    (async () => {
      const countryList = (await getCountries()).map((x) => {
        x.id = String(x.id).toLowerCase();
        return x;
      });
      setCountryList(countryList);
    })();
  }, []);

  // when textwordsList changes or enumerations changes
  // and there are no values yet
  // put values into the correct shape + lookup enumeration labels + update state with mapped values
  useEffect(() => {
    if ((textwordsList || filteredValues) && enumerations) {
      const textwordsVal = (account ? textwordsList : filteredValues) || {};
      const textwords = Object.keys(textwordsVal).filter((k) => {
        return textwordsVal[k];
      });

      setValues(
        textwords.map((k) => {
          const value = textwordsVal[k];
          // disbaled enum lookup for country
          // const labels = getEnumLabels(value, enumerations);
          // return { ...labels, key: k };
          return { ...value, key: `${value.accountId}_${value.id}` };
        })
      );
    }
  }, [textwordsList, enumerations]); // when textwordsList or enumerations changes

  const updateMyData = async (
    rowIndex,
    columnId,
    value,
    verifiedpartyid,
    updateTableData
  ) => {
    const textWordRef = damApp
      .database()
      .ref(`textwords/${values[rowIndex].key}`);
    const textword = {};
    const now = moment().unix();
    textword[columnId] = value || '';
    textword['verifiedpartyid_senttobq'] = `${verifiedpartyid}_false`;
    textword['modified'] = now;
    try {
      await textWordRef.update(textword);
    } catch (e) {
      logger.error('[updateMyData] - error updating textword: ', e);
    }

    if (updateTableData) {
      setValues((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [columnId]: value,
              ['modified']: now,
            };
          }
          return row;
        })
      );
    }
  };

  const updatePartyIdData = async (
    rowIndex,
    party_id,
    party_name,
    valid,
    updateTableData,
    canopus_id
  ) => {
    const textWordRef = damApp
      .database()
      .ref(`textwords/${values[rowIndex].key}`);
    const textword = {};
    const now = moment().unix();

    const isCanopusValid = canopus_id && canopus_id !== '' ? true : false;
    // we use this to check the process of canopus being added to party service record after initial ingestion
    const hasPartyIdAndNoCanopusId = isCanopusValid === false && valid === true;

    const accountid_id = values[rowIndex].accountid_id;
    textword['party_id'] =
      (party_id && /^\d+$/.test(party_id) && parseInt(party_id, 10)) || null;
    textword['party_name'] = party_name;
    textword['partyid_valid'] = `${party_id}_${valid}`;
    textword['partyIdValid'] = valid;
    textword['verifiedpartyid'] = false;
    textword['verifiedpartyid_accountid_id'] = `false_${accountid_id}`;
    textword['verifiedpartyid_senttobq'] = `false_false`;
    textword['canopusId'] = canopus_id ? `${canopus_id}` : null;
    textword['canopusName'] = ''; // clearing this as we're not pulling canopus record - just setting canopus ID based on party record - so for name look at party Name.
    textword['canopus_verified'] = null; // clearing this as we're not pulling canopus record
    textword['has_party_id_and_no_canopus_id'] = hasPartyIdAndNoCanopusId;
    textword['verifiedcanopus'] = false;
    textword['modified'] = now;
    try {
      await textWordRef.update(textword);
    } catch (e) {
      logger.error('[updatePartyIdData] - error updating textword: ', e);
    }

    if (updateTableData) {
      setValues((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              ['party_id']: party_id,
              ['party_name']: party_name,
              ['canopusName']: '',
              ['canopusId']: canopus_id,
              ['verifiedcanopus']: false,
              ['canopus_verified']: null,
              ['modified']: now,
            };
          }
          return row;
        })
      );
    }
  };

  const tableDataUpdated = (rowIndex, columnId, value, modified) => {
    // logger.info(`tableDataUpdated called for col: ${columnId} with value: ${value}`);
    setValues((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
            ['modified']: modified,
          };
        }
        return row;
      })
    );
  };

  const verifyCanopus = async (rowIndex, verify) => {
    const textWordRef = damApp
      .database()
      .ref(`textwords/${values[rowIndex].key}`);
    const canopusId = values[rowIndex].canopusId;
    const accountid_id = values[rowIndex].accountid_id;
    const now = moment().unix();
    const textword: any = {
      verifiedcanopus: verify,
      verifiedcanopus_accountid_id: `${verify}_${accountid_id}`,
      verifiedcanopus_senttobq: `${verify}_false`,
      canopus_verified: `${canopusId}_${verify}`,
      modified: now,
    };

    if (verify === false) {
      textword.addressbookMappingValid = false;
      textword.mappingDDAccountId = null;
      textword.mappingDDAddressbookId = null;
      textword.mapped_senttobq = 'false_false';
    }

    try {
      await textWordRef.update(textword);
    } catch (e) {
      logger.error('[verifyCanopus] - error updating textword: ', e);
    }

    setValues((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            ['addressbookMappingValid']: textword.addressbookMappingValid,
            ['mappingDDAccountId']: textword.mappingDDAccountId,
            ['mappingDDAddressbookId']: textword.mappingDDAddressbookId,
            ['verifiedcanopus']: verify,
            ['modified']: now,
          };
        }
        return row;
      })
    );
  };

  const verifyPartyServiceId = async (rowIndex, verify) => {
    const textWordRef = damApp
      .database()
      .ref(`textwords/${values[rowIndex].key}`);
    const partyServiceId = values[rowIndex].party_id;
    const accountid_id = values[rowIndex].accountid_id;
    const now = moment().unix();
    const textword: any = {
      verifiedpartyid: verify,
      verifiedpartyid_accountid_id: `${verify}_${accountid_id}`,
      verifiedpartyid_senttobq: `${verify}_false`,
      partyid_verified: `${partyServiceId}_${verify}`,
      modified: now,
    };

    if (verify === false) {
      textword.addressbookMappingValid = false;
      textword.mappingDDAccountId = null;
      textword.mappingDDAddressbookId = null;
      textword.mapped_senttobq = 'false_false';
    }

    try {
      await textWordRef.update(textword);
    } catch (e) {
      logger.error('[verifyPartyServiceId] - error updating textword: ', e);
    }

    setValues((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            ['addressbookMappingValid']: textword.addressbookMappingValid,
            ['mappingDDAccountId']: textword.mappingDDAccountId,
            ['mappingDDAddressbookId']: textword.mappingDDAddressbookId,
            ['verifiedpartyid']: verify,
            ['modified']: now,
          };
        }
        return row;
      })
    );
  };

  const EditablePartyServiceIDCell = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    updatePartyIdData, // This is a custom function that we supplied to our table instance
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);
    const [loading, setLoading] = React.useState(false);
    const hasPartyServiceName =
      original.party_name !== '' && original.party_name !== undefined;
    const [verifiedpartyid, setVerifiedpartyid] = React.useState(
      original.verifiedpartyid
    );
    const [error, setError] = React.useState('');

    const onChange = async (e) => {
      const partyId = e.target.value;
      const re = /^\d+$/g;
      if (!re.test(partyId) && partyId !== '') {
        return;
      }

      const optinIndex = 'country_channel_category_brandId_tag';
      const optinIndexValue =
        `${original.country}_sms_${original.category}_${partyId}_${original.tag}`.toLowerCase();

      setLoading(true);
      const optinExists = await checkOptinExists(
        optinIndex,
        optinIndexValue,
        original.key
      );
      if (optinExists === false) {
        setValue(partyId);
        setError('');
        await lookupPartyId(partyId);
        setLoading(false);
      } else {
        const oldValue = initialValue || '';
        setValue(oldValue);
        await lookupPartyId(oldValue);
        setError('Duplicate key for optin');
        setLoading(false);
      }
    };

    // const verifiedcanopus = original.verifiedcanopus;

    // this should be in features/partyservice/api/get getById or similar
    const lookupPartyId = async (partyId) => {
      // resets values
      await updatePartyIdData(index, partyId, '', false, true, '');
      const partyServiceRecord = await getPartyServiceRecord(partyId);
      const valid = partyServiceRecord.party_name !== '';
      await updatePartyIdData(
        index,
        partyId,
        `${partyServiceRecord.party_name || ''}`,
        valid,
        true,
        partyServiceRecord.canopus_id
      );
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    React.useEffect(() => {
      // logger.info('original changed')
      setVerifiedpartyid(original.verifiedpartyid);
    }, [original]);

    return (
      <React.Fragment>
        {!verifiedpartyid && (
          <React.Fragment>
            {error !== '' ? (
              <Alert color="danger" className={slicktextStyles.validationError}>
                {error}
              </Alert>
            ) : null}
            <Row>
              <Col sm={10}>
                <Input type="text" value={value} onChange={onChange} />
              </Col>
              <Col sm={2}>
                {loading && (
                  <img
                    src="/images/loading.gif"
                    className={slicktextStyles.canopusLoader}
                  />
                )}
                {!loading && hasPartyServiceName && (
                  <TickIcon fill="black" class={iconStyles.tick} />
                )}
                {!loading && !hasPartyServiceName && (
                  <CrossIcon fill="black" class={iconStyles.cross} />
                )}
              </Col>
            </Row>
          </React.Fragment>
        )}
        {verifiedpartyid && `${value}`}
      </React.Fragment>
    );
  };

  const EditablePartyServiceIDVerified = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    cellInfo,
    verifyPartyServiceId,
  }) => {
    const [value, setValue] = React.useState(initialValue);
    const hasPartyServiceName =
      original.party_name !== '' && original.party_name !== undefined;

    const handleClick = async (verify: boolean) => {
      // logger.info(`handleClick called: ${verify}`);
      setValue(verify);
      await verifyPartyServiceId(index, verify);
    };

    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const partyServiceId = original.party_id;

    if (value === true) {
      return (
        <React.Fragment>
          true
          {
            <Button
              onClick={() => handleClick(false)}
              className={slicktextStyles.textwordsVerify}
            >
              Unverify
            </Button>
          }
        </React.Fragment>
      );
    }

    const partyServiceIsNumber = /^\d+$/.test(partyServiceId);
    const haspartyservice =
      !isNilOrEmpty(partyServiceId) && partyServiceIsNumber === true;

    return (
      <React.Fragment>
        false
        {haspartyservice && hasPartyServiceName && (
          <Button
            onClick={() => handleClick(true)}
            className={slicktextStyles.textwordsVerify}
          >
            Verify
          </Button>
        )}
      </React.Fragment>
    );
  };

  const EditableCountrySelect = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    updateMyData,
  }) => {
    const [value, setValue] = React.useState(initialValue);
    const verifiedpartyid = original.verifiedpartyid;

    const onChange = async (e) => {
      setValue(e.target.value);
      await updateMyData(index, id, e.target.value, verifiedpartyid, true);
    };

    React.useEffect(() => {
      // logger.debug(`useEffect: ${initialValue}`)
      setValue(initialValue);
    }, [initialValue]);

    const country = R.filter(R.propEq('value', value), countries);
    const countryValue = Object.keys(country)[0] || '';
    // logger.debug(`typeof value ${typeof value}, value: ${value}`)

    return (
      <React.Fragment>
        {value === '' || typeof value === 'undefined' ? (
          <Alert color="danger" className={slicktextStyles.validationError}>
            Country must be set
          </Alert>
        ) : null}
        <Input type="select" value={countryValue} onChange={onChange}>
          <option value="" key=""></option>
          {countryOptions}
        </Input>
      </React.Fragment>
    );
  };

  const EditableCategorySelect = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    updateMyData,
  }) => {
    const verifiedpartyid = original.verifiedpartyid;
    const active = original.isRemovedFromSlicktext !== true;
    const [value, setValue] = React.useState(initialValue);
    const [error, setError] = React.useState('');

    // we do this same exact thing multiple times but the code is repeated!
    // this could simply be part of features/optins/api/update - update() could reindex etc
    const onChange = async (e) => {
      logger.debug(`EditableCategoryCell onchange (${e.target.value})`);
      const newValue = e.target.value;
      const optinIndex = 'country_channel_category_brandId_tag';
      const optinIndexValue =
        `${original.country}_sms_${newValue}_${original.party_id}_${original.tag}`.toLowerCase();
      const optinExists = await checkOptinExists(
        optinIndex,
        optinIndexValue,
        original.key
      );
      if (optinExists === false) {
        setValue(newValue);
        setError('');
        logger.debug(`calling updateData to set value: ${newValue}`);
        await updateMyData(index, id, newValue, verifiedpartyid, true);
      } else {
        // handle showing some error message about duplicate optin exists
        logger.debug(`setting category to initialValue: ${initialValue}`);
        setValue(initialValue || '');
        setError('Duplicate key for optin');
      }
    };

    React.useEffect(() => {
      // logger.debug(`useEffect: ${initialValue}`)
      setValue(initialValue);
    }, [initialValue]);

    return (
      <React.Fragment>
        {error !== '' ? (
          <Alert color="danger" className={slicktextStyles.validationError}>
            {error}
          </Alert>
        ) : null}
        <Input
          type="select"
          value={value}
          onChange={onChange}
          disabled={!active}
        >
          <option value="" key="">
            Please Select
          </option>
          {categoryOptions}
        </Input>
      </React.Fragment>
    );
  };

  const EditableTagSelect = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    updateMyData,
  }) => {
    const verifiedpartyid = original.verifiedpartyid;
    const active = original.isRemovedFromSlicktext !== true;
    const [value, setValue] = React.useState(initialValue);
    const [error, setError] = React.useState('');

    const onChange = async (e) => {
      const val = e.target.value;
      setValue(val);
    };

    const onBlur = async (e) => {
      logger.debug(`EditableCategoryCell onBlur (${e.target.value})`);
      const newValue = e.target.value;
      const optinIndex = 'country_channel_category_brandId_tag';
      const optinIndexValue =
        `${original.country}_sms_${original.category}_${original.party_id}_${newValue}`.toLowerCase();
      const optinExists = await checkOptinExists(
        optinIndex,
        optinIndexValue,
        original.key
      );
      if (optinExists === false) {
        setValue(newValue);
        setError('');
        logger.debug(`calling updateData to set value: ${newValue}`);
        await updateMyData(index, id, newValue, verifiedpartyid, true);
      } else {
        // handle showing some error message about duplicate optin exists
        logger.debug(`setting tag to initialValue: ${initialValue}`);
        setValue(initialValue || '');
        setError('Duplicate key for optin');
      }
    };

    React.useEffect(() => {
      // logger.debug(`useEffect: ${initialValue}`)
      setValue(initialValue);
    }, [initialValue]);

    return (
      <React.Fragment>
        {error !== '' ? (
          <Alert color="danger" className={slicktextStyles.validationError}>
            {error}
          </Alert>
        ) : null}
        <Input
          type="text"
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
          disabled={!active}
        />
      </React.Fragment>
    );
  };

  const EditableLabelSelect = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    updateMyData,
  }) => {
    const [value, setValue] = React.useState(initialValue);
    const verifiedpartyid = original.verifiedpartyid;

    const onChange = async (e) => {
      setValue(e.target.value);
      await updateMyData(index, id, e.target.value, verifiedpartyid, true);
    };

    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <React.Fragment>
        {value === '' || typeof value === 'undefined' ? (
          <Alert color="danger" className={slicktextStyles.validationError}>
            Label must be set
          </Alert>
        ) : null}
        <Input type="select" value={value} onChange={onChange}>
          <option value="" key=""></option>
          {labelOptions}
        </Input>
      </React.Fragment>
    );
  };

  const EditableShortCodeSelect = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    updateMyData,
  }) => {
    const [value, setValue] = React.useState(initialValue);
    const verifiedpartyid = original.verifiedpartyid;

    const onChange = async (e) => {
      setValue(e.target.value);
      await updateMyData(index, id, e.target.value, verifiedpartyid, true);
    };

    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <Input type="select" value={value} onChange={onChange}>
        <option value="" key=""></option>
        {shortCodeOptions}
      </Input>
    );
  };

  const EditableCanopusVerified = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    cellInfo,
    verifyCanopus,
  }) => {
    const [value, setValue] = React.useState(initialValue);
    const hasCanopusName =
      original.canopusName !== '' && original.canopusName !== undefined;

    const handleClick = async (verify: boolean) => {
      // logger.info(`handleClick called: ${verify}`);
      setValue(verify);
      await verifyCanopus(index, verify);
    };

    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const canopusId = original.canopusId;

    // we're not going to implement unverify for now
    if (value === true) {
      return (
        <React.Fragment>
          true
          {
            <Button disabled={true} className={slicktextStyles.textwordsVerify}>
              Unverify
            </Button>
          }
        </React.Fragment>
      );
    }

    const canopusIsNumber = /^\d+$/.test(canopusId);
    const hascanopus = !isNilOrEmpty(canopusId) && canopusIsNumber === true;

    return (
      <React.Fragment>
        false
        {hascanopus && hasCanopusName && (
          <Button disabled={true} className={slicktextStyles.textwordsVerify}>
            Verify
          </Button>
        )}
      </React.Fragment>
    );
  };

  const editMappingClick = (textword, index) => {
    logger.debug(
      `[editMappingClick] clicked, with textword: ${JSON.stringify(textword)}`
    );
    if (textword.party_id && textword.party_id !== '') {
      setMappingTextword(textword);
      setMappingTextwordIndex(index);
      setMappingModalIsOpen(true);
    }
  };

  const columns = React.useMemo(() => {
    const cols = [
      {
        Header: 'Key',
        accessor: 'key',
        id: 'key',
      },
      {
        Header: 'ID',
        accessor: 'id',
        id: 'id',
      },
      {
        Header: 'Textword',
        accessor: 'word',
        id: 'word',
      },
      {
        Header: 'Contacts Count',
        Filter: NumberFilter,
        filter: 'between',
        accessor: 'contacts_count',
        id: 'contacts_count',
      },
      {
        Header: 'Canopus ID',
        accessor: 'canopusId',
        id: 'canopusId',
      },
      {
        Header: 'Canopus Name',
        accessor: 'canopusName',
        id: 'canopusName',
      },
      {
        Header: 'Canopus Verified',
        accessor: (row) => {
          if (row.verifiedcanopus === true) {
            return 'true';
          }

          return 'false';
        },
        id: 'verifiedcanopus',
      },
      {
        Header: 'Party Service ID',
        accessor: 'party_id',
        Cell: EditablePartyServiceIDCell,
        id: 'party_id',
      },
      {
        Header: 'Party Service Name',
        accessor: 'party_name',
        id: 'party_name',
      },
      {
        Header: 'Party Service Verified',
        accessor: 'verifiedpartyid',
        Cell: EditablePartyServiceIDVerified,
        id: 'verifiedpartyid',
      },
      {
        Header: 'Optin Category',
        accessor: 'category',
        filter: 'equals',
        Cell: EditableCategorySelect,
        id: 'category',
      },
      {
        Header: 'Optin Tag',
        accessor: 'tag',
        filter: 'equals',
        Cell: EditableTagSelect,
        id: 'tag',
      },
      {
        Header: 'Label',
        accessor: 'label',
        Filter: SelectColumnFilterFactory(labels),
        filter: 'equals',
        Cell: EditableLabelSelect,
        id: 'label',
      },
      {
        Header: 'Territory (Operating Country)',
        accessor: 'country',
        Filter: SelectColumnFilter,
        filter: 'equals',
        Cell: EditableCountrySelect,
        id: 'country',
      },
      {
        Header: 'Short/Long Code',
        accessor: 'shortcode',
        Filter: SelectColumnFilter,
        filter: 'equals',
        Cell: EditableShortCodeSelect,
        id: 'shortcode',
      },
      {
        Header: 'Added',
        accessor: 'added',
        id: 'added',
      },
      {
        Header: 'Modified',
        accessor: (row) => {
          if (row.modified) {
            return moment
              .unix(parseInt(row.modified))
              .format('YYYY-MM-DD HH:mm:ss');
          }

          return '';
        },
        id: 'modified',
      },
      {
        Header: 'Sent to BQ',
        accessor: (row) => {
          if (row.sentToBQ) {
            return moment
              .unix(parseInt(row.sentToBQ))
              .format('YYYY-MM-DD HH:mm:ss');
          }

          return '';
        },
        id: 'sentToBQ',
      },
      {
        Header: 'Removed from Slicktext',
        accessor: (row) => {
          if (row.isRemovedFromSlicktext === true) {
            return 'true';
          }

          return 'false';
        },
        id: 'isRemovedFromSlicktext',
      },
    ];

    const mappingDDAccountIdCol = {
      Header: 'Mapping DD Account ID',
      accessor: 'mappingDDAccountId',
      Cell: ({ row }) => {
        const hasPartyId =
          row.original.party_name && row.original.party_name !== '';
        const isVerifiedPartyId = row.original.verifiedpartyid === true;
        const allowMapping = hasPartyId && isVerifiedPartyId ? true : false;
        return (
          <div className={slicktextStyles.mappingDiv}>
            <span
              onClick={
                allowMapping
                  ? () => editMappingClick(row.original, row.index)
                  : null
              }
              className={slicktextStyles.mappingSpan}
            >
              <input
                type="text"
                value={row.original.mappingDDAccountId || ''}
                readOnly={true}
                disabled={allowMapping ? false : true}
                className={allowMapping ? slicktextStyles.mappingInput : null}
              />
            </span>
          </div>
        );
      },
      id: 'mappingDDAccountId',
    };

    const mappingDDAddressbookIdCol = {
      Header: 'Mapping DD Addressbook ID',
      accessor: 'mappingDDAddressbookId',
      Cell: ({ row }) => {
        const hasPartyId =
          row.original.party_name && row.original.party_name !== '';
        const isVerifiedPartyId = row.original.verifiedpartyid === true;
        const allowMapping = hasPartyId && isVerifiedPartyId ? true : false;
        return (
          <div className={slicktextStyles.mappingDiv}>
            <span
              {...(allowMapping && {
                onClick: () => editMappingClick(row.original, row.index),
              })}
              className={slicktextStyles.mappingSpan}
            >
              <input
                type="text"
                value={row.original.mappingDDAddressbookId || ''}
                readOnly={true}
                disabled={allowMapping ? false : true}
                className={allowMapping ? slicktextStyles.mappingInput : null}
              />
            </span>
          </div>
        );
      },
      id: 'mappingDDAddressbookId',
    };

    const addressbookMappingValidCol = {
      Header: 'Addressbook Mapping Valid',
      accessor: (row) => {
        if (row.addressbookMappingValid === true) {
          return 'Valid Mapping';
        } else if (row.addressbookMappingValid === false) {
          return 'Invalid Mapping';
        }

        return '';
      },
      id: 'addressbookMappingValid',
      Cell: ({ row }) => {
        const invalid = row.original.addressbookMappingValid === false;
        const valid = row.original.addressbookMappingValid === true;
        return (
          <div className={slicktextStyles.mappingDiv}>
            {invalid && <Alert color="danger">Invalid Mapping</Alert>}
            {valid && <Alert color="success">Valid Mapping</Alert>}
          </div>
        );
      },
    };

    if (textwordMappingEnabled === true) {
      return [
        ...cols,
        mappingDDAccountIdCol,
        mappingDDAddressbookIdCol,
        addressbookMappingValidCol,
      ];
    }

    return cols;
  }, [textwordMappingEnabled]);

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFnExp,
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  // memoize values - the heavy lifting is now done in the above useEffect
  const records = React.useMemo(() => values, [values]);

  let hiddenColumns: any = R.keys(
    R.pickBy((val, key) => val === false, userCols)
  );

  const tableData = useTable(
    {
      columns,
      data: records,
      filterTypes,
      defaultColumn,
      autoResetFilters: false,
      autoResetSortBy: false,
      autoResetPage: false,
      initialState: {
        sortBy: [
          {
            id: 'added',
            desc: true,
          },
        ],
        pageSize: !account ? 20 : undefined,
        pageIndex: 0,
      },
      autoResetHiddenColumns: false,
      updateMyData,
      updatePartyIdData,
      verifyCanopus,
      verifyPartyServiceId,
    } as any,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;

  React.useEffect(() => {
    hiddenColumns = R.keys(R.pickBy((val, key) => val === false, userCols));
    tableData.setHiddenColumns(hiddenColumns);
  }, [userCols]);

  if (error) {
    return <div>{`Error: ${error.message}`}</div>;
  }

  if (userColsError) {
    return <div>Error loading cols</div>;
  }

  const toggleColumnsSelect = () =>
    setIsColumnsSelectOpen(!isColumnsSelectOpen);
  const toggleFiltersSelect = () =>
    setIsFiltersSelectOpen(!isFiltersSelectOpen);

  return (
    <div className={pagesStyles.fullHeight}>
      <Row>
        <Col sm={{ size: 12 }}>
          <Button
            onClick={toggleColumnsSelect}
            className={slicktextStyles.rowButton}
          >
            Columns
          </Button>
          {!account && (
            <Button
              onClick={toggleFiltersSelect}
              className={slicktextStyles.rowButton}
            >
              Filters
            </Button>
          )}
        </Col>
      </Row>
      {!account && (
        <Collapse isOpen={isFiltersSelectOpen}>
          <Row>
            <Col className={pagesStyles.divider}></Col>
          </Row>
          <Row>
            <Col sm={5}>
              <DDTableFilters
                path="textwords"
                db="dam"
                title="Find SMS Marketing Lists"
                setLoading={setFilterLoading}
                rowsToExport={tableData.rows.map((row) => row.values) || []}
                setFilteredValues={setFilteredValues}
                filters={[
                  {
                    primary: true,
                    type: 'search',
                    query: 'slicktext_search',
                    customHandler: false,
                    submitable: true,
                    labels: {
                      placeholder: '',
                    },
                    options: [
                      {
                        id: 'word',
                        label: 'Textword',
                        placeholder: 'Search for Textword',
                      },
                      {
                        id: 'party_name',
                        label: 'Party Service Name',
                        placeholder: 'Search for party service name',
                      },
                      {
                        id: 'accountName',
                        label: 'Account Name',
                        placeholder: 'Search for account name',
                      },
                      {
                        number: true,
                        id: 'id',
                        label: 'Textword ID',
                        placeholder: 'Search for ID',
                      },
                      {
                        number: true,
                        id: 'party_id',
                        label: 'Party Service ID',
                        placeholder: 'Search for party service id',
                      },
                      {
                        toggle: true,
                        id: 'country',
                        label: 'Country',
                        value: countryList,
                      },
                      {
                        toggle: true,
                        id: 'category',
                        label: 'Category',

                        value: Object.values(categories).map((x: any) => {
                          return { id: x.value, label: x.label };
                        }),
                      },
                    ],
                  },
                ]}
              />
            </Col>
          </Row>
        </Collapse>
      )}
      <Row>
        <Col sm={12}>
          <Collapse isOpen={isColumnsSelectOpen}>
            <ColumnsSelector
              columns={columns}
              userCols={userCols}
              tableName={TABLE_NAME}
            />
          </Collapse>
        </Col>
      </Row>
      {loading || userColsLoading || filterLoading ? (
        <Loader loading={true} />
      ) : (
        <Row className={pagesStyles.fullHeight}>
          <Col sm={{ size: 12 }} className={pagesStyles.fullHeight}>
            <TextwordsTable {...tableData} account={account} />
          </Col>
        </Row>
      )}
      <TextwordMappingModal
        textword={mappingTextword}
        mappingModalIsOpen={mappingModalIsOpen}
        setMappingModalIsOpen={setMappingModalIsOpen}
        setMappingTextword={setMappingTextword}
        tableDataUpdated={tableDataUpdated}
        mappingTextwordIndex={mappingTextwordIndex}
      />
    </div>
  );
};
