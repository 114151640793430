import * as React from 'react';
import * as fileSaver from 'file-saver';
import * as xlsx from 'xlsx';
import { Button } from 'reactstrap';
import { logger, serverLogger } from '../../logging';

const accountsStyles = require('./accounts.css');

export const ExportToExcel = ({ csvData, fileName, user }) => {

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
    logger.info(csvData);
    if (user) {
      const userEmail = user['email'];
      // logger.info('reidrecting to', from.pathname);
      serverLogger(`User ${userEmail} exported file: ${fileName}`, 'info');
    }

    const ws = xlsx.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    fileSaver.saveAs(data, fileName + fileExtension);
  }

  return (
    <Button variant="warning" onClick={(e) => exportToCSV(csvData, fileName)} className={accountsStyles.button}>Export</Button>
  )
}