"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.js
var src_exports = {};
__export(src_exports, {
  Checkbox: () => Checkbox_default,
  CheckboxGroup: () => CheckboxGroup_default,
  CurrencyInput: () => CurrencyInput_default,
  Feedback: () => Feedback_default,
  Field: () => Field_default,
  FieldHelpIcon: () => FieldHelpIcon_default,
  Form: () => Form_default,
  FormGroup: () => FormGroup_default,
  Input: () => Input_default,
  Label: () => Label_default,
  Radio: () => Radio_default,
  RadioGroup: () => RadioGroup_default,
  RequiredAsterisk: () => RequiredAsterisk,
  RequiredKey: () => RequiredKey
});
module.exports = __toCommonJS(src_exports);

// src/Form.js
var import_react = __toESM(require("react"));
var import_prop_types = __toESM(require("prop-types"));
var import_uuid = require("uuid");
var import_reactstrap = require("reactstrap");
var import_formik = require("formik");
var import_jsx_runtime = require("react/jsx-runtime");
var useFormikFocusFirstInvalidField = (id, focusInvalidField, invalidInputSelectors) => {
  const formik = (0, import_formik.useFormikContext)();
  const [submitCount, setSubmitCount] = (0, import_react.useState)(formik.submitCount);
  (0, import_react.useEffect)(() => {
    if (focusInvalidField && !formik.isValid && formik.submitCount > submitCount) {
      const form = document.getElementById(id);
      const firstInvalidInput = form.querySelector(invalidInputSelectors);
      firstInvalidInput == null ? void 0 : firstInvalidInput.focus();
      setSubmitCount(formik.submitCount);
    }
  }, [id, focusInvalidField, formik.isValid, formik.submitCount, invalidInputSelectors, submitCount, formik.errors]);
};
var FocusableForm = (_a) => {
  var _b = _a, { children, focusProps } = _b, rest = __objRest(_b, ["children", "focusProps"]);
  useFormikFocusFirstInvalidField(...focusProps);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_reactstrap.Form, __spreadProps(__spreadValues({}, rest), { children }));
};
var Form = (_a) => {
  var _b = _a, {
    initialValues,
    enableReinitialize,
    onSubmit,
    initialStatus,
    initialErrors,
    initialTouched,
    onReset,
    validationSchema,
    validate,
    focusInvalidField,
    id,
    innerRef,
    invalidInputSelectors,
    children
  } = _b, rest = __objRest(_b, [
    "initialValues",
    "enableReinitialize",
    "onSubmit",
    "initialStatus",
    "initialErrors",
    "initialTouched",
    "onReset",
    "validationSchema",
    "validate",
    "focusInvalidField",
    "id",
    "innerRef",
    "invalidInputSelectors",
    "children"
  ]);
  const formId = id || (0, import_uuid.v4)();
  const focusProps = [id = formId, focusInvalidField, invalidInputSelectors];
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
    import_formik.Formik,
    {
      initialValues,
      enableReinitialize,
      onSubmit,
      onReset,
      initialStatus,
      initialErrors,
      initialTouched,
      validationSchema,
      validate,
      innerRef,
      children: (props) => /* @__PURE__ */ (0, import_jsx_runtime.jsx)(FocusableForm, __spreadProps(__spreadValues({ "data-testid": "form-container", tag: import_formik.Form, id: formId, focusProps }, rest), { children: typeof children === "function" ? children(props) : children }))
    }
  );
};
Form.propTypes = {
  children: import_prop_types.default.oneOfType([import_prop_types.default.func, import_prop_types.default.node]),
  enableReinitialize: import_prop_types.default.bool,
  focusInvalidField: import_prop_types.default.bool,
  id: import_prop_types.default.string,
  initialErrors: import_prop_types.default.object,
  initialStatus: import_prop_types.default.any,
  initialTouched: import_prop_types.default.object,
  initialValues: import_prop_types.default.object.isRequired,
  innerRef: import_prop_types.default.any,
  invalidInputSelectors: import_prop_types.default.string,
  onReset: import_prop_types.default.func,
  onSubmit: import_prop_types.default.func,
  validate: import_prop_types.default.func,
  validationSchema: import_prop_types.default.object
};
Form.defaultProps = {
  focusInvalidField: true,
  invalidInputSelectors: 'input[aria-invalid="true"], div.is-invalid input:first-of-type:not([hidden]):not([style*="display:none"]):not([style*="display: none"])',
  onSubmit: () => {
  }
};
FocusableForm.propTypes = {
  children: import_prop_types.default.node,
  focusProps: import_prop_types.default.array
};
var Form_default = Form;

// src/Field.js
var import_react7 = __toESM(require("react"));
var import_prop_types7 = __toESM(require("prop-types"));
var import_reactstrap6 = require("reactstrap");
var import_uuid3 = require("uuid");

// src/Feedback.js
var import_react2 = __toESM(require("react"));
var import_prop_types2 = __toESM(require("prop-types"));
var import_reactstrap2 = require("reactstrap");
var import_formik2 = require("formik");
var import_jsx_runtime2 = require("react/jsx-runtime");
var AvFeedback = (_a) => {
  var _b = _a, { children } = _b, rest = __objRest(_b, ["children"]);
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsxs)(import_reactstrap2.FormFeedback, __spreadProps(__spreadValues({}, rest), { children: [
    /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("i", { className: "icon icon-attention", "aria-hidden": true }),
    /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("div", { className: "sr-only", children: "Error:" }),
    children
  ] }));
};
var Feedback = (_a) => {
  var _b = _a, { name } = _b, rest = __objRest(_b, ["name"]);
  const feedbackId = `${name}-feedback`.toLowerCase();
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(import_formik2.ErrorMessage, __spreadValues({ id: feedbackId, component: AvFeedback, name }, rest));
};
AvFeedback.propTypes = {
  children: import_prop_types2.default.node
};
Feedback.propTypes = {
  name: import_prop_types2.default.string.isRequired
};
var Feedback_default = Feedback;

// src/FormGroup.js
var import_react3 = __toESM(require("react"));
var import_prop_types3 = __toESM(require("prop-types"));
var import_reactstrap3 = require("reactstrap");
var import_classnames = __toESM(require("classnames"));
var import_formik3 = require("formik");
var import_jsx_runtime3 = require("react/jsx-runtime");
var FormGroup = (_a) => {
  var _b = _a, { className, for: For } = _b, props = __objRest(_b, ["className", "for"]);
  const [, metadata] = (0, import_formik3.useField)(For);
  const classname = (0, import_classnames.default)(className, metadata.touched && metadata.error && `text-danger`);
  return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(import_reactstrap3.FormGroup, __spreadValues({ className: classname }, props));
};
FormGroup.propTypes = {
  className: import_prop_types3.default.string,
  /** Used to match the wrapped input. Must be the same name given to the input field. */
  for: import_prop_types3.default.string
};
var FormGroup_default = FormGroup;

// src/Input.js
var import_react4 = __toESM(require("react"));
var import_prop_types4 = __toESM(require("prop-types"));
var import_classnames2 = __toESM(require("classnames"));
var import_reactstrap4 = require("reactstrap");
var import_formik4 = require("formik");
var import_jsx_runtime4 = require("react/jsx-runtime");
var Input = (_a) => {
  var _b = _a, { name, tag: Tag, className, onChange: propsOnChange, validate, feedback, help, required } = _b, rest = __objRest(_b, ["name", "tag", "className", "onChange", "validate", "feedback", "help", "required"]);
  const [_a2, ..._b2] = (0, import_formik4.useField)({
    name,
    validate
  }), _c = _a2, { onChange } = _c, field = __objRest(_c, ["onChange"]), [metadata] = _b2;
  const classes = (0, import_classnames2.default)(
    className,
    metadata.touched ? "is-touched" : "is-untouched",
    metadata.error ? "av-invalid" : "av-valid",
    metadata.touched && metadata.error && "is-invalid",
    rest.type === "checkbox" && metadata.touched && metadata.error && "was-validated"
  );
  const error = !!metadata.touched && !!metadata.error;
  const feedbackId = error && feedback ? `${name}-feedback`.toLowerCase() : "";
  const helpMessageId = help ? ` ${name}-helpmessage`.toLowerCase() : "";
  const extraProps = {};
  if (rest.type === "checkbox") {
    extraProps.checked = !!field.value;
  }
  return /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(
    Tag,
    __spreadValues(__spreadValues(__spreadValues({
      className: classes,
      onChange: (e) => {
        onChange(e);
        if (propsOnChange) {
          propsOnChange(e);
        }
      },
      name,
      invalid: error,
      "aria-describedby": feedbackId + helpMessageId,
      "aria-required": required
    }, field), extraProps), rest)
  );
};
Input.propTypes = {
  /** Identifies the field and matches the validation */
  name: import_prop_types4.default.string.isRequired,
  /** Class name passed to the input. */
  className: import_prop_types4.default.string,
  /** Will add default feedback id to aria-describedby. */
  feedback: import_prop_types4.default.bool,
  /** Will add default help message id to aria-describedby. Used by <Field />. */
  help: import_prop_types4.default.bool,
  onChange: import_prop_types4.default.func,
  /** Will add aria-required to input. */
  required: import_prop_types4.default.bool,
  /** The Node or tag to substitute as the input field. Default is reactstrap Input tag. */
  tag: import_prop_types4.default.oneOfType([import_prop_types4.default.func, import_prop_types4.default.string]),
  validate: import_prop_types4.default.func
};
Input.defaultProps = {
  tag: import_reactstrap4.Input
};
var Input_default = Input;

// src/Label.js
var import_react6 = __toESM(require("react"));
var import_prop_types6 = __toESM(require("prop-types"));
var import_uuid2 = require("uuid");
var import_reactstrap5 = require("reactstrap");

// src/FieldHelpIcon.js
var import_react5 = __toESM(require("react"));
var import_prop_types5 = __toESM(require("prop-types"));
var import_message_core = __toESM(require("@availity/message-core"));
var import_icon = __toESM(require("@availity/icon"));
var import_jsx_runtime5 = require("react/jsx-runtime");
var OPEN_FIELD_HELP = "nav:help:field";
var triggerFieldHelp = (id) => {
  import_message_core.default.send({
    event: OPEN_FIELD_HELP,
    id
  });
};
var handleKeyPress = (event, id) => {
  if (event.key === "Enter") {
    triggerFieldHelp(id);
  }
};
var FieldHelpIcon = ({ id, color = "primary", size = "1x", labelId, isHelpVideoType }) => /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(
  import_icon.default,
  {
    role: "link",
    "data-testid": "field-help-icon",
    name: isHelpVideoType ? "video-help" : "help-circle",
    size,
    color,
    onClick: () => triggerFieldHelp(id),
    tabIndex: 0,
    onKeyDown: (event) => handleKeyPress(event, id),
    "aria-hidden": "false",
    "aria-label": "help",
    "aria-describedby": labelId
  }
);
FieldHelpIcon.propTypes = {
  id: import_prop_types5.default.string,
  color: import_prop_types5.default.string,
  size: import_prop_types5.default.string,
  labelId: import_prop_types5.default.string,
  isHelpVideoType: import_prop_types5.default.bool
};
var FieldHelpIcon_default = FieldHelpIcon;

// src/Label.js
var import_jsx_runtime6 = (
  // required-asterisk class available in availity-uikit v4.1.5+
  require("react/jsx-runtime")
);
var RequiredAsterisk = () => /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(
  "strong",
  {
    className: "text-danger d-inline align-text-top",
    "data-testid": "required-asterisk",
    style: {
      fontSize: "130%",
      lineHeight: "100%"
    },
    children: "*"
  }
);
var RequiredKey = () => /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)("div", { children: [
  "Fields marked with an asterisk ",
  /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(RequiredAsterisk, {}),
  " are required."
] });
var Label = (_a) => {
  var _b = _a, { helpId, id, required, children, isHelpVideoType } = _b, attributes = __objRest(_b, ["helpId", "id", "required", "children", "isHelpVideoType"]);
  const labelId = id || (0, import_uuid2.v4)();
  const Wrapper = ({ children: children2 }) => {
    if (helpId && (attributes.className || attributes.style)) {
      return /* @__PURE__ */ (0, import_jsx_runtime6.jsx)("div", { className: attributes.className, style: attributes.style, children: children2 });
    }
    return /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_jsx_runtime6.Fragment, { children: children2 });
  };
  return /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)(Wrapper, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)(import_reactstrap5.Label, __spreadProps(__spreadValues({ id: labelId, "data-testid": "label" }, attributes), { children: [
      required ? /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)(import_jsx_runtime6.Fragment, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(RequiredAsterisk, {}),
        " "
      ] }) : null,
      children
    ] })),
    helpId ? /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(FieldHelpIcon_default, { labelId, id: helpId, isHelpVideoType }) : null
  ] });
};
Label.propTypes = {
  /** Id of the label element. Default is generated UUID. */
  id: import_prop_types6.default.string,
  /** Help topic id, adds <FieldHelpIcon/> next to the label (should not be within label for accessibility). */
  helpId: import_prop_types6.default.string,
  /** Will add <RequiredAsterisk /> to label. */
  required: import_prop_types6.default.bool,
  children: import_prop_types6.default.oneOfType([import_prop_types6.default.func, import_prop_types6.default.node]),
  /** Allows the type of `<FieldHelpIcon/>` to be changed between help-icon and video-help */
  isHelpVideoType: import_prop_types6.default.bool
};
var Label_default = Label;

// src/Field.js
var import_jsx_runtime7 = require("react/jsx-runtime");
var colSizes = ["xs", "sm", "md", "lg", "xl"];
var Field = (_a) => {
  var _b = _a, {
    helpMessage,
    helpId,
    required,
    label,
    labelHidden,
    inputClass,
    labelClass,
    name: id,
    size,
    disabled,
    readOnly,
    grid,
    labelAttrs,
    groupAttrs,
    prepend,
    append,
    children,
    isHelpVideoType
  } = _b, attributes = __objRest(_b, [
    "helpMessage",
    "helpId",
    "required",
    "label",
    "labelHidden",
    "inputClass",
    "labelClass",
    "name",
    "size",
    "disabled",
    "readOnly",
    "grid",
    "labelAttrs",
    "groupAttrs",
    "prepend",
    "append",
    "children",
    "isHelpVideoType"
  ]);
  let row = false;
  const inputId = attributes.id || (0, import_uuid3.v4)();
  const col = {};
  const labelCol = {};
  if (grid) {
    for (const colSize of colSizes) {
      if (grid[colSize]) {
        row = true;
        const sizeNum = Number.parseInt(grid[colSize], 10);
        col[colSize] = sizeNum;
        labelCol[colSize] = 12 - sizeNum;
      }
    }
  }
  let input = /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(
    Input_default,
    __spreadValues({
      name: id,
      id: inputId,
      className: inputClass,
      size,
      required,
      disabled,
      readOnly,
      feedback: true,
      help: !!helpMessage
    }, attributes)
  );
  if (prepend || append) {
    input = /* @__PURE__ */ (0, import_jsx_runtime7.jsxs)(import_reactstrap6.InputGroup, { children: [
      prepend && /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(import_reactstrap6.InputGroupAddon, { addonType: "prepend", children: typeof prepend === "string" ? /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(import_reactstrap6.InputGroupText, { children: prepend }) : prepend }),
      input,
      append && /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(import_reactstrap6.InputGroupAddon, { addonType: "append", children: typeof append === "string" ? /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(import_reactstrap6.InputGroupText, { children: append }) : append }),
      /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(Feedback_default, { name: id })
    ] });
  }
  const help = helpMessage ? /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(import_reactstrap6.FormText, { id: `${id}-helpmessage`.toLowerCase(), children: helpMessage }) : null;
  const feedback = /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(Feedback_default, { name: id });
  let inputRow = row ? /* @__PURE__ */ (0, import_jsx_runtime7.jsxs)(import_reactstrap6.Col, __spreadProps(__spreadValues({}, col), { children: [
    input,
    !prepend && !append && /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(Feedback_default, { name: id }),
    help
  ] })) : input;
  if (children && typeof children === "function") {
    inputRow = children({ input: inputRow, feedback });
  }
  const check = attributes.type === "checkbox";
  return /* @__PURE__ */ (0, import_jsx_runtime7.jsxs)(FormGroup_default, __spreadProps(__spreadValues({ for: id, check, disabled, row }, groupAttrs), { children: [
    check && inputRow,
    label && /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(
      Label_default,
      __spreadProps(__spreadValues(__spreadValues({
        id: `${inputId}-label`,
        for: inputId,
        className: labelClass,
        hidden: labelHidden,
        size,
        required: !!required,
        helpId,
        disabled,
        isHelpVideoType
      }, labelCol), labelAttrs), {
        children: label
      })
    ),
    !check && inputRow,
    !row && !prepend && !append && feedback,
    !row && help
  ] }));
};
Field.propTypes = {
  /** Identifies the field and matches the validation schema. */
  name: import_prop_types7.default.string.isRequired,
  /** Append an InputAddon to the end of the Input. */
  append: import_prop_types7.default.node,
  /** Optionally override the way the input is rendered with child render prop. */
  children: import_prop_types7.default.func,
  /** Disable the <Field />. */
  disabled: import_prop_types7.default.bool,
  /* Object mapping number of columns to the label and input. */
  grid: import_prop_types7.default.object,
  /** Pass additional attributes to Form Group */
  groupAttrs: import_prop_types7.default.object,
  /** Help topic id, adds <FieldHelpIcon/> next to the label (should not be within label for accessibility). */
  helpId: import_prop_types7.default.string,
  /** Display info text below the field */
  helpMessage: import_prop_types7.default.node,
  /** Class names passed to the input tag. */
  inputClass: import_prop_types7.default.string,
  /** Contents of the field label. Renders within a Reactstrap <Label />. */
  label: import_prop_types7.default.node,
  /** Pass additional attributes to the label */
  labelAttrs: import_prop_types7.default.object,
  /** Class names passed to the label tag. */
  labelClass: import_prop_types7.default.string,
  /** Used to hide the label. */
  labelHidden: import_prop_types7.default.bool,
  /** Append an InputAddon to the start of the Input. */
  prepend: import_prop_types7.default.node,
  /** Mark the field as read only. */
  readOnly: import_prop_types7.default.bool,
  /** Will add aria-required to input, will add <RequiredAsterisk /> to label. */
  required: import_prop_types7.default.bool,
  /** Size of the input field. Potential values: "lg", "sm" */
  size: import_prop_types7.default.string,
  /** The Node or tag to substitute as the input field. Default is reactstrap Input tag. */
  tag: import_prop_types7.default.oneOfType([import_prop_types7.default.func, import_prop_types7.default.string]),
  /** Allows the type of `<FieldHelpIcon/>` to be changed between help-icon and video-help */
  isHelpVideoType: import_prop_types7.default.bool
};
Field.defaultProps = {
  tag: import_reactstrap6.Input
};
var Field_default = Field;

// src/CheckboxGroup.js
var import_react8 = __toESM(require("react"));
var import_prop_types8 = __toESM(require("prop-types"));
var import_classnames3 = __toESM(require("classnames"));
var import_formik5 = require("formik");
var import_jsx_runtime8 = require("react/jsx-runtime");
var CheckboxGroupContext = (0, import_react8.createContext)();
var useCheckboxGroup = (name) => {
  const { setFieldValue } = (0, import_formik5.useFormikContext)();
  const _a = (0, import_react8.useContext)(CheckboxGroupContext), { name: groupName, groupOnChange, value = [] } = _a, rest = __objRest(_a, ["name", "groupOnChange", "value"]);
  const toggle = () => {
    const valueArray = [...value];
    const indexOfVal = valueArray.indexOf(name);
    if (indexOfVal === -1) {
      valueArray.push(name);
    } else {
      valueArray.splice(indexOfVal, 1);
    }
    setFieldValue(groupName, valueArray);
    if (groupOnChange) {
      groupOnChange(valueArray);
    }
  };
  return __spreadValues({ toggle, value: value.indexOf(name) > -1 }, rest);
};
var CheckboxGroup = (_a) => {
  var _b = _a, {
    name,
    children,
    onChange: groupOnChange,
    groupClassName,
    label,
    labelClassName,
    required,
    helpId,
    isHelpVideoType
  } = _b, rest = __objRest(_b, [
    "name",
    "children",
    "onChange",
    "groupClassName",
    "label",
    "labelClassName",
    "required",
    "helpId",
    "isHelpVideoType"
  ]);
  const [field, metadata] = (0, import_formik5.useField)(name);
  const classes = (0, import_classnames3.default)(
    groupClassName,
    "form-control border-0 p-0 h-auto",
    metadata.touched ? "is-touched" : "is-untouched",
    metadata.touched && metadata.error && "is-invalid"
  );
  let tag = "div";
  let legend = null;
  if (label) {
    tag = "fieldset";
    const legendId = `${name}-legend`.toLowerCase();
    const srRequiredAsterisk = required ? "* " : null;
    const styles = { cursor: "default", lineHeight: "inherit", color: "#000" };
    const labelClasses = (0, import_classnames3.default)("form-inline", labelClassName, !labelClassName && "h4 font-weight-normal");
    legend = /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(import_jsx_runtime8.Fragment, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)("legend", { id: legendId, className: "sr-only", children: [
        srRequiredAsterisk,
        label
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("div", { className: labelClasses, style: styles, children: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(Label_default, { tag: "div", "aria-hidden": true, helpId, required, isHelpVideoType, children: label }) })
    ] });
  }
  return /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(CheckboxGroupContext.Provider, { value: __spreadProps(__spreadValues({}, field), { groupOnChange, metadata }), children: /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(FormGroup_default, __spreadProps(__spreadValues({ tag, for: name }, rest), { children: [
    legend,
    /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("div", { className: classes, "data-testid": `check-items-${name}`, children }),
    /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(Feedback_default, { name })
  ] })) });
};
CheckboxGroup.propTypes = {
  /** Name of the checkbox group. Should match name given in initialValues/validationSchema. */
  name: import_prop_types8.default.string.isRequired,
  children: import_prop_types8.default.node,
  /** Class name to apply to the form control. */
  groupClassName: import_prop_types8.default.string,
  /** Help topic id, adds <FieldHelpIcon/> next to the label (should not be within label for accessibility). */
  helpId: import_prop_types8.default.string,
  /** Label for the group or checkboxes. */
  label: import_prop_types8.default.node,
  onChange: import_prop_types8.default.func,
  /** Class name to apply to the Label. Default is Legend styling */
  labelClassName: import_prop_types8.default.string,
  /** Will add <RequiredAsterisk /> to label. */
  required: import_prop_types8.default.bool,
  /** Allows the type of `<FieldHelpIcon/>` to be changed between help-icon and video-help */
  isHelpVideoType: import_prop_types8.default.bool
};
var CheckboxGroup_default = CheckboxGroup;

// src/Checkbox.js
var import_react9 = __toESM(require("react"));
var import_prop_types9 = __toESM(require("prop-types"));
var import_reactstrap7 = require("reactstrap");
var import_uuid4 = require("uuid");
var import_classnames4 = __toESM(require("classnames"));
var import_jsx_runtime9 = require("react/jsx-runtime");
var Checkbox = (_a) => {
  var _b = _a, {
    className,
    groupClassName,
    groupName,
    helpId,
    id,
    inline,
    label,
    value: checkValue,
    isHelpVideoType
  } = _b, attributes = __objRest(_b, [
    "className",
    "groupClassName",
    "groupName",
    "helpId",
    "id",
    "inline",
    "label",
    "value",
    "isHelpVideoType"
  ]);
  const { value, toggle, metadata } = useCheckboxGroup(checkValue);
  const [inputId] = (0, import_react9.useState)(id || (0, import_uuid4.v4)());
  const classes = (0, import_classnames4.default)(
    className,
    metadata.touched ? "is-touched" : "is-untouched",
    metadata.touched && metadata.error && "is-invalid"
  );
  const errorIndicated = !!metadata.touched && !!metadata.error;
  const groupFeedbackId = errorIndicated && groupName ? `${groupName}-feedback`.toLowerCase() : "";
  const labelId = `${inputId}-label`.toLowerCase();
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)(FormGroup_default, { for: inputId, className: groupClassName, check: true, inline, disabled: attributes.disabled, children: [
    /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
      import_reactstrap7.Input,
      __spreadProps(__spreadValues({
        id: inputId,
        name: inputId,
        className: classes,
        type: "checkbox",
        invalid: errorIndicated,
        "aria-describedby": groupFeedbackId
      }, attributes), {
        value: checkValue,
        checked: value,
        onChange: toggle
      })
    ),
    /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(Label_default, { check: true, id: labelId, for: inputId, helpId, isHelpVideoType, children: label })
  ] });
};
Checkbox.propTypes = {
  className: import_prop_types9.default.string,
  /** Disables the checkbox. */
  disabled: import_prop_types9.default.bool,
  groupClassName: import_prop_types9.default.string,
  /** Should match <CheckboxGroup /> name to accessibly link input to form feedback. */
  groupName: import_prop_types9.default.string,
  /** Help topic id, adds <FieldHelpIcon/> next to the label (should not be within label for accessibility). */
  helpId: import_prop_types9.default.string,
  /** Id and name for the checkbox. */
  id: import_prop_types9.default.string,
  /** Will render the checkbox inline with other checkboxes. Default: true. */
  inline: import_prop_types9.default.bool,
  /** Label for the checkbox. */
  label: import_prop_types9.default.node,
  /** Value of the checkbox. */
  value: import_prop_types9.default.oneOfType([import_prop_types9.default.string, import_prop_types9.default.bool, import_prop_types9.default.object]),
  /** Allows the type of `<FieldHelpIcon/>` to be changed between help-icon and video-help */
  isHelpVideoType: import_prop_types9.default.bool
};
Checkbox.defaultProps = {
  inline: true
};
var Checkbox_default = Checkbox;

// src/RadioGroup.js
var import_react10 = __toESM(require("react"));
var import_prop_types10 = __toESM(require("prop-types"));
var import_classnames5 = __toESM(require("classnames"));
var import_formik6 = require("formik");
var import_jsx_runtime10 = require("react/jsx-runtime");
var RadioGroupContext = (0, import_react10.createContext)();
var useRadioGroup = (radioValue) => {
  const { setFieldValue } = (0, import_formik6.useFormikContext)();
  const _a = (0, import_react10.useContext)(RadioGroupContext), { name: groupName, value = "", groupOnChange } = _a, rest = __objRest(_a, ["name", "value", "groupOnChange"]);
  const setValue = () => {
    setFieldValue(groupName, radioValue);
    if (groupOnChange) {
      groupOnChange(radioValue);
    }
  };
  return __spreadValues({ groupName, setValue, value: value === radioValue }, rest);
};
var RadioGroup = (_a) => {
  var _b = _a, {
    name,
    children,
    label,
    onChange: groupOnChange,
    groupClassName,
    inline = false,
    helpId,
    labelClassName,
    required,
    isHelpVideoType
  } = _b, rest = __objRest(_b, [
    "name",
    "children",
    "label",
    "onChange",
    "groupClassName",
    "inline",
    "helpId",
    "labelClassName",
    "required",
    "isHelpVideoType"
  ]);
  const [field, metadata] = (0, import_formik6.useField)(name);
  const classes = (0, import_classnames5.default)(
    groupClassName,
    "form-control border-0 p-0 h-auto",
    metadata.touched ? "is-touched" : "is-untouched",
    metadata.touched && metadata.error && "is-invalid"
  );
  let tag = "div";
  let legend = null;
  if (label) {
    tag = "fieldset";
    const legendId = `${name}-legend`.toLowerCase();
    const styles = { cursor: "default", lineHeight: "inherit", color: "#000" };
    const labelClasses = (0, import_classnames5.default)("form-inline", labelClassName, !labelClassName && "h4 font-weight-normal");
    legend = /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)(import_jsx_runtime10.Fragment, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)("legend", { id: legendId, className: "sr-only", children: [
        required ? "* " : null,
        label
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime10.jsx)("div", { className: labelClasses, style: styles, children: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(Label_default, { tag: "div", "aria-hidden": true, helpId, required, isHelpVideoType, children: label }) })
    ] });
  }
  return /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(RadioGroupContext.Provider, { value: __spreadProps(__spreadValues({}, field), { groupOnChange, metadata, inline }), children: /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)(FormGroup_default, __spreadProps(__spreadValues({ tag, for: name }, rest), { children: [
    legend,
    /* @__PURE__ */ (0, import_jsx_runtime10.jsx)("div", { className: classes, "data-testid": `radio-items-${name}`, children }),
    /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(Feedback_default, { name })
  ] })) });
};
RadioGroup.propTypes = {
  children: import_prop_types10.default.node,
  groupClassName: import_prop_types10.default.string,
  helpId: import_prop_types10.default.string,
  inline: import_prop_types10.default.bool,
  label: import_prop_types10.default.node,
  name: import_prop_types10.default.string,
  onChange: import_prop_types10.default.func,
  labelClassName: import_prop_types10.default.string,
  required: import_prop_types10.default.bool,
  isHelpVideoType: import_prop_types10.default.bool
};
var RadioGroup_default = RadioGroup;

// src/Radio.js
var import_react11 = __toESM(require("react"));
var import_prop_types11 = __toESM(require("prop-types"));
var import_reactstrap8 = require("reactstrap");
var import_uuid5 = require("uuid");
var import_classnames6 = __toESM(require("classnames"));
var import_jsx_runtime11 = require("react/jsx-runtime");
var Radio = (_a) => {
  var _b = _a, {
    label,
    id,
    name,
    value: checkValue,
    className,
    groupClassName,
    children,
    helpId,
    isHelpVideoType
  } = _b, attributes = __objRest(_b, [
    "label",
    "id",
    "name",
    "value",
    "className",
    "groupClassName",
    "children",
    "helpId",
    "isHelpVideoType"
  ]);
  const { value, setValue, metadata, inline } = useRadioGroup(checkValue);
  const [inputId] = (0, import_react11.useState)(id || (0, import_uuid5.v4)());
  const classes = (0, import_classnames6.default)(
    className,
    metadata.touched ? "is-touched" : "is-untouched",
    metadata.touched && metadata.error && "is-invalid"
  );
  const errorIndicated = !!metadata.touched && !!metadata.error;
  const feedbackId = errorIndicated && name ? `${name}-feedback`.toLowerCase() : "";
  const labelId = `${inputId}-label`.toLowerCase();
  return /* @__PURE__ */ (0, import_jsx_runtime11.jsxs)(FormGroup_default, { for: inputId, check: true, className: groupClassName, inline, disabled: attributes.disabled, children: [
    /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(
      import_reactstrap8.Input,
      __spreadProps(__spreadValues({
        id: inputId,
        name: name || inputId,
        className: classes,
        type: "radio",
        invalid: errorIndicated,
        "aria-describedby": feedbackId
      }, attributes), {
        value: checkValue,
        checked: value,
        onChange: setValue
      })
    ),
    /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(Label_default, { check: true, id: labelId, for: inputId, helpId, isHelpVideoType, children: label || children })
  ] });
};
Radio.propTypes = {
  children: import_prop_types11.default.node,
  className: import_prop_types11.default.string,
  /** Disables the radio button. */
  disabled: import_prop_types11.default.bool,
  groupClassName: import_prop_types11.default.string,
  /** Help topic id, adds <FieldHelpIcon/> next to the label (should not be within label for accessibility). */
  helpId: import_prop_types11.default.string,
  /** Id for the radio button. */
  id: import_prop_types11.default.string,
  /** Label for the radio button. */
  label: import_prop_types11.default.node,
  /** Should match <RadioGroup /> name for validation and accessibly linking button to form feedback. */
  name: import_prop_types11.default.string,
  /** Value of the radio button. */
  value: import_prop_types11.default.oneOfType([import_prop_types11.default.string, import_prop_types11.default.bool, import_prop_types11.default.object]),
  /** Allows the type of `<FieldHelpIcon/>` to be changed between help-icon and video-help */
  isHelpVideoType: import_prop_types11.default.bool
};
var Radio_default = Radio;

// src/CurrencyInput.tsx
var import_react_currency_input_field = __toESM(require("react-currency-input-field"));
var import_classnames7 = __toESM(require("classnames"));
var import_formik7 = require("formik");
var import_jsx_runtime12 = require("react/jsx-runtime");
var CurrencyInput = (_a) => {
  var _b = _a, { name, onValueChanged, id, value, placeholder, disabled } = _b, attributes = __objRest(_b, ["name", "onValueChanged", "id", "value", "placeholder", "disabled"]);
  const { setFieldValue, setFieldTouched } = (0, import_formik7.useFormikContext)();
  const [, metadata] = (0, import_formik7.useField)({
    name
  });
  const classes = (0, import_classnames7.default)(
    metadata.touched ? "is-touched" : "is-untouched",
    metadata.error ? "av-invalid" : "av-valid",
    metadata.touched && metadata.error && "is-invalid",
    "form-control"
  );
  const formatDecimals = (value2) => __async(void 0, null, function* () {
    setFieldTouched(name, true);
    if (value2 === "") {
      setFieldValue(name, void 0);
      if (onValueChanged) {
        onValueChanged(void 0);
      }
      return;
    }
    const noCommasValue = value2 == null ? void 0 : value2.replace(/,/g, "");
    const number = Number(noCommasValue);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
    const decimalValue = number.toLocaleString(void 0, options).replace(/,/g, "");
    setFieldValue(name, decimalValue);
    if (onValueChanged) {
      onValueChanged(decimalValue);
    }
  });
  return /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)(import_jsx_runtime12.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
      import_react_currency_input_field.default,
      __spreadValues({
        id,
        name,
        className: classes,
        prefix: "$",
        placeholder,
        disabled,
        decimalsLimit: 2,
        value,
        "aria-invalid": !!metadata.error,
        onBlur: (event) => __async(void 0, null, function* () {
          formatDecimals(event.target.value.replace("$", ""));
        }),
        onValueChange: onValueChanged,
        allowNegativeValue: false,
        transformRawValue: (rawValue) => {
          if (rawValue && rawValue.startsWith(".")) {
            return `0${rawValue}`;
          }
          return rawValue;
        }
      }, attributes)
    ),
    /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(Feedback_default, { name })
  ] });
};
var CurrencyInput_default = CurrencyInput;
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  Checkbox,
  CheckboxGroup,
  CurrencyInput,
  Feedback,
  Field,
  FieldHelpIcon,
  Form,
  FormGroup,
  Input,
  Label,
  Radio,
  RadioGroup,
  RequiredAsterisk,
  RequiredKey
});
