import * as R from 'ramda';
import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Alert,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Toast,
  ToastBody,
  ToastHeader,
} from 'reactstrap';
import { AddressbookMetadata } from '../../../functions/src/services/infra/dotdigital';
import { DDAccountSelector } from '../../components/DDAccountSelector';
import { DDAddressbookSelector } from '../../components/DDAddressbookSelector';
import { IFBWorkflowFormSelector } from '../../components/IFBWorkflowFormSelector';
import { logger } from '../../logging';
import { getDAMApp } from '../../services/firebase';

const workflowsStyles = require('./workflows.css');

export const ContactsMigrationArgs = ({
  args,
  setWorkflowArgs,
  setWorkflowArgsValid,
  disabled,
}) => {
  const damApp = getDAMApp();
  const [sourceAccountId, setSourceAccountId] = useState(null);
  const [sourceAddressbookId, setSourceAddressbookId] = useState(null);
  const [destinationAccountId, setDestinationAccountId] = useState(null);
  const [destinationAddressbookId, setDestinationAddressbookId] =
    useState(null);
  const [formId, setFormId] = useState('');
  const [errorMessages, setErrorMessages] = useState({});
  const [sourceContactsCount, setSourceContactsCount] = useState(null);
  const [destinationContactsCount, setDestinationContactsCount] =
    useState(null);
  const [sourceAccountValid, setSourceAccountValid] = useState(true);
  const [destinationAccountValid, setDestinationAccountValid] = useState(true);
  const [isPrivacyWorkflow, setIsPrivacyWorkflow] = useState(false);
  const [isStoreWorkflow, setIsStoreWorkflow] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (args === null) {
      return;
    }

    if (isMounted) {
      setSourceAccountId(args.source_account || null);
      setSourceAddressbookId(args.source_addressbook || null);
      setDestinationAccountId(args.destination_account || null);
      setDestinationAddressbookId(args.destination_addressbook || null);
      setFormId(args.form_id || '');
      logger.debug(`setting isPrivacyWorkflow to: ${args.is_privacy || false}`);
      setIsPrivacyWorkflow(args.is_privacy || false);
      setIsStoreWorkflow(args.is_store || false);
    }

    return () => {
      isMounted = false;
    };
  }, [args]);

  useEffect(() => {
    let isMounted = true;
    const newMessage: any = { ...errorMessages };
    const isSameAddressbook =
      `${sourceAccountId}${sourceAddressbookId}` ===
      `${destinationAccountId}${destinationAddressbookId}`;
    if (isSameAddressbook && sourceAccountId && sourceAddressbookId) {
      newMessage['isSameAddressbook'] = {
        message:
          "You can't have the same addressbook for both source and destination",
      };
    } else {
      delete newMessage.isSameAddressbook;
    }

    if (sourceAccountId && sourceAccountValid === false) {
      newMessage['sourceAccountError'] = {
        message: 'No IFB creds for source account',
      };
    } else {
      delete newMessage.sourceAccountError;
    }

    if (destinationAccountId && destinationAccountValid === false) {
      newMessage['destinationAccountError'] = {
        message: 'No IFB creds for destination account',
      };
    } else {
      delete newMessage.destinationAccountError;
    }

    setErrorMessages(newMessage);

    if (
      sourceAccountId &&
      sourceAddressbookId &&
      destinationAccountId &&
      destinationAddressbookId &&
      formId &&
      !isSameAddressbook &&
      sourceAccountValid &&
      destinationAccountValid
    ) {
      const args = {
        source_account: parseInt(sourceAccountId, 10),
        source_addressbook: parseInt(sourceAddressbookId, 10),
        destination_account: parseInt(destinationAccountId, 10),
        destination_addressbook: parseInt(destinationAddressbookId, 10),
        form_id: formId,
        is_privacy: isPrivacyWorkflow,
        is_store: isStoreWorkflow,
      };

      if (isMounted) {
        setWorkflowArgs(args);
        setWorkflowArgsValid(true);
        setErrorMessages({});
      }
    } else {
      if (isMounted) {
        setWorkflowArgsValid(false);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    sourceAccountId,
    sourceAddressbookId,
    destinationAccountId,
    destinationAddressbookId,
    formId,
    isPrivacyWorkflow,
    isStoreWorkflow,
  ]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (!sourceAddressbookId) {
        setSourceContactsCount(null);
        return;
      }

      setSourceAddressbookContactsCount(sourceAccountId, sourceAddressbookId);
    }

    return () => {
      isMounted = false;
    };
  }, [sourceAddressbookId]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (!destinationAddressbookId) {
        setDestinationContactsCount(null);
        return;
      }

      setDestinationAddressbookContactsCount(
        destinationAccountId,
        destinationAddressbookId
      );
    }

    return () => {
      isMounted = false;
    };
  }, [sourceAddressbookId]);

  const setSourceAddressbookContactsCount = async (
    accountId,
    addressbookId
  ) => {
    const contacts = await getAddressbooksCount(accountId, addressbookId);
    setSourceContactsCount(contacts);
  };

  const setDestinationAddressbookContactsCount = async (
    accountId,
    addressbookId
  ) => {
    const contacts = await getAddressbooksCount(accountId, addressbookId);
    setDestinationContactsCount(contacts);
  };

  const getAddressbooksCount = async (accountId, addressbookId) => {
    try {
      logger.info(
        `[getAddressbooksCount] calling accountManager-callableGetAddressbook`
      );
      const callable = damApp
        .functions()
        .httpsCallable('accountManager-callableGetAddressbook');
      const res = await callable({ accountId, addressbookId });
      const addressbook = res.data as AddressbookMetadata;
      return addressbook.contacts;
    } catch (e) {
      logger.error(`[getAddressbooksCount] error: ${e}`);
      return null;
    }
  };

  const isAccountValid = async (id) => {
    logger.debug(`[isAccountValid] called for id: ${id}`);
    let valid = false;
    try {
      const ddAccountRef = damApp
        .database()
        .ref(`accounts/dotdigital`)
        .orderByChild('ddAccountId')
        .equalTo(id);
      const ddAccountSnap = await ddAccountRef.once('value');
      let ddAccountKey = '';
      ddAccountSnap.forEach((snap) => {
        ddAccountKey = snap.key;
      });

      logger.debug(`key: ${ddAccountKey}`);
      const credentialsRef = damApp
        .database()
        .ref(`credentials/${ddAccountKey}`);
      const credentialsSnap = await credentialsRef.once('value');
      const count = credentialsSnap.numChildren();
      if (count > 0) {
        credentialsSnap.forEach((snap) => {
          if (snap.val().name === 'form-builder') {
            logger.debug(`cred.name: ${snap.val().name}`);
            valid = true;
          }
        });
      }
    } catch (e) {
      logger.error(`[isAccountValid] error: ${e}`);
    }

    return valid;
  };

  const isWorkflowPrivacy = async (sourceId, destId) => {
    logger.debug(
      `[isWorkflowPrivacy] called for account ids: ${sourceId} and ${destId}`
    );
    let isPrivacy = false;
    // check sourceAccount
    try {
      const ddAccountSourceRef = damApp
        .database()
        .ref(`accounts/dotdigital`)
        .orderByChild('ddAccountId')
        .equalTo(`${sourceId}`);
      const ddAccountSourceSnap = await ddAccountSourceRef.once('value');

      ddAccountSourceSnap.forEach((snap) => {
        if (snap.val().ddAccountType === 'exile') {
          logger.debug(
            `[isWorkflowPrivacy] returns true for source account: ${sourceId}`
          );
          isPrivacy = true;
        }
      });
    } catch (e) {
      logger.error(
        `[isWorkflowPrivacy] error checking source account type: ${e}`
      );
    }

    // check destinationAccount
    try {
      const ddAccountDestRef = damApp
        .database()
        .ref(`accounts/dotdigital`)
        .orderByChild('ddAccountId')
        .equalTo(`${destId}`);
      const ddAccountDestSnap = await ddAccountDestRef.once('value');

      ddAccountDestSnap.forEach((snap) => {
        if (snap.val().ddAccountType === 'exile') {
          logger.debug(
            `[isWorkflowPrivacy] returns true for dest account: ${destId}`
          );
          isPrivacy = true;
        }
      });
    } catch (e) {
      logger.error(
        `[isAccountValid] error checking destination account type: ${e}`
      );
    }

    return isPrivacy;
  };

  const isWorkflowStore = async (sourceId) => {
    logger.debug(`[isWorkflowStore] called for source account id: ${sourceId}`);
    let isStore = false;
    // check sourceAccount
    try {
      const ddAccountSourceRef = damApp
        .database()
        .ref(`accounts/dotdigital`)
        .orderByChild('ddAccountId')
        .equalTo(`${sourceId}`);
      const ddAccountSourceSnap = await ddAccountSourceRef.once('value');

      ddAccountSourceSnap.forEach((snap) => {
        if (snap.val().ddAccountType === 'store') {
          logger.debug(
            `[isWorkflowStore] returns true for source account: ${sourceId}`
          );
          isStore = true;
        }
      });
    } catch (e) {
      logger.error(
        `[isWorkflowStore] error checking source account type: ${e}`
      );
    }

    return isStore;
  };

  const handleSourceAccountChange = async (id) => {
    logger.debug(`handleSourceAccountChanged: id: ${id}`);
    // check if accountId valid (has IFB creds)
    const accountValid = await isAccountValid(id);
    logger.debug(`handleSourceAccountChanged: accountValid: ${accountValid}`);
    setSourceAccountValid(accountValid);
    setSourceAccountId(id);
    setSourceAddressbookId(null);

    // check if either are exile (privacy)
    const isPrivacy = await isWorkflowPrivacy(id, destinationAccountId);
    logger.debug(
      `[handleSourceAccountChange] setting isPrivacyWorkflow to: ${isPrivacy}`
    );
    setIsPrivacyWorkflow(isPrivacy);

    // check if source is store
    const isStore = await isWorkflowStore(id);
    logger.debug(
      `[handleSourceAccountChange] setting isStoreWorkflow to: ${isStore}`
    );
    setIsStoreWorkflow(isStore);
  };

  const handleDestinationAccountChange = async (id) => {
    logger.debug(`handleDestinationAccountChanged: id: ${id}`);
    const accountValid = await isAccountValid(id);
    setDestinationAccountValid(accountValid);
    setDestinationAccountId(id);
    setDestinationAddressbookId(null);
    const isPrivacy = await isWorkflowPrivacy(sourceAccountId, id);
    logger.debug(
      `[handleDestinationAccountChange] setting isPrivacyWorkflow to: ${isPrivacy}`
    );
    setIsPrivacyWorkflow(isPrivacy);
  };

  return (
    <Toast className={workflowsStyles.toast}>
      <ToastHeader>Contacts Migration Options</ToastHeader>
      <ToastBody>
        {errorMessages && Object.keys(errorMessages).length > 0
          ? R.map((errorMessage: any) => {
              return (
                <Row key={errorMessage}>
                  <Col sm={12}>
                    <Alert color="danger">
                      {errorMessage && errorMessages[errorMessage].message}
                    </Alert>
                  </Col>
                </Row>
              );
            }, Object.keys(errorMessages))
          : null}
        <Row>
          <Col sm={12}>
            <Alert color="info">
              {`Accounts must have an authorization/standard acquisition audience in lytics for the destination account.`}
            </Alert>
          </Col>
        </Row>
        <FormGroup row className={workflowsStyles.workflowModalBodyRow}>
          <Label for="sourceAccount" sm={2}>
            Source DD Account
          </Label>
          <Col sm={6}>
            <DDAccountSelector
              setAccountId={handleSourceAccountChange}
              accountId={sourceAccountId}
              disabled={disabled}
            />
          </Col>
          <Label for="sourceAccountId" sm={2}>
            Source DD Account ID
          </Label>
          <Col sm={2}>
            <Input
              type="text"
              name="sourceAccountId"
              value={(sourceAccountId && sourceAccountId) || ''}
              disabled={true}
            ></Input>
          </Col>
        </FormGroup>
        <FormGroup row className={workflowsStyles.workflowModalBodyRow}>
          <Label for="sourceAddressbook" sm={2}>
            Source DD Addressbook
          </Label>
          <Col sm={6}>
            <DDAddressbookSelector
              setAddressbookId={setSourceAddressbookId}
              addressbookId={sourceAddressbookId}
              accountId={sourceAccountId}
              disabled={disabled}
              excludeNonConsented={false}
            />
          </Col>
          <Label for="sourceAddressbookId" sm={2}>
            Source DD Addressbook ID
          </Label>
          <Col sm={2}>
            <Input
              type="text"
              name="sourceAddressbookId"
              value={(sourceAddressbookId && sourceAddressbookId) || ''}
              disabled={true}
            ></Input>
            {sourceAddressbookId &&
            (sourceContactsCount || sourceContactsCount === 0) ? (
              <span
                className={workflowsStyles.contactsCount}
              >{`[Addressbook contacts count = ${sourceContactsCount}]`}</span>
            ) : null}
          </Col>
        </FormGroup>
        <FormGroup row className={workflowsStyles.workflowModalBodyRow}>
          <Label for="destinationAccount" sm={2}>
            Destination DD Account
          </Label>
          <Col sm={6}>
            <DDAccountSelector
              setAccountId={handleDestinationAccountChange}
              accountId={destinationAccountId}
              disabled={disabled}
            />
          </Col>
          <Label for="destinationAccountId" sm={2}>
            Destination DD Account ID
          </Label>
          <Col sm={2}>
            <Input
              type="text"
              name="destinationAccountId"
              value={(destinationAccountId && destinationAccountId) || ''}
              disabled={true}
            ></Input>
          </Col>
        </FormGroup>
        <FormGroup row className={workflowsStyles.workflowModalBodyRow}>
          <Label for="destinationAddressbook" sm={2}>
            Destination DD Addressbook
          </Label>
          <Col sm={6}>
            <DDAddressbookSelector
              setAddressbookId={setDestinationAddressbookId}
              addressbookId={destinationAddressbookId}
              accountId={destinationAccountId}
              disabled={disabled}
              excludeNonConsented={true}
            />
          </Col>
          <Label for="destinationAddressbookId" sm={2}>
            Destination DD Addressbook ID
          </Label>
          <Col sm={2}>
            <Input
              type="text"
              name="destinationAddressbookId"
              value={
                (destinationAddressbookId && destinationAddressbookId) || ''
              }
              disabled={true}
            ></Input>
            {destinationAddressbookId &&
            (destinationContactsCount || destinationContactsCount === 0) ? (
              <span
                className={workflowsStyles.contactsCount}
              >{`[Addressbook contacts count = ${destinationContactsCount}]`}</span>
            ) : null}
          </Col>
        </FormGroup>
        <FormGroup row className={workflowsStyles.workflowModalBodyRow}>
          <Label for="form" sm={2}>
            IFB Form
          </Label>
          <Col sm={6}>
            <IFBWorkflowFormSelector
              formId={formId}
              setFormId={setFormId}
              disabled={disabled}
            />
          </Col>
          <Label for="formId" sm={2}>
            IFB Form ID
          </Label>
          <Col sm={2}>
            <Input
              type="text"
              name="formId"
              value={(formId && formId) || ''}
              disabled={true}
            ></Input>
          </Col>
        </FormGroup>
        <FormGroup row className={workflowsStyles.workflowModalBodyRow}>
          <Label for="isPrivacy" sm={2}>
            Is Privacy Workflow (source or destination account is exile)
          </Label>
          <Col sm={6}>
            <Input
              type="checkbox"
              checked={isPrivacyWorkflow}
              id="isPrivacy"
              className={workflowsStyles.workflowModalCheckbox}
              disabled={true}
            />
          </Col>
          <Label for="isStore" sm={2}>
            Is Store Workflow (source account is store)
          </Label>
          <Col sm={2}>
            <Input
              type="checkbox"
              checked={isStoreWorkflow}
              id="isStore"
              className={workflowsStyles.workflowModalCheckbox}
              disabled={true}
            />
          </Col>
        </FormGroup>
      </ToastBody>
    </Toast>
  );
};
